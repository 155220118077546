.serviceComContainerRow{
    width: 98.9vw;
    height: 55vh;
    padding: 0rem 12rem;
    display: flex;
    /* background-color: pink; */
    /* background-color: rgb(253, 246, 246); */
    /* background-image: linear-gradient(#fdf6f6, #ead8fe); */
    flex-direction: row;
}

.serviceComContainerRowRev{
    width: 98.9vw;
    height: 55vh;
    padding: 0rem 12rem;
    display: flex;
    gap: 1rem;
    /* background-color: pink; */
    /* background-color: rgb(253, 246, 246); */
    /* background-image: linear-gradient(#fdf6f6, #fdf9f6); */
    flex-direction: row-reverse;
}

/* .changeDirection{
} */

.serviceLeftContainer{
    width: 60%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    /* text-align: left; */
    /* background-color: rgb(120, 0, 0); */
}

.serviceRightContainer{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
}

.serViceImg{
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 10px 10px 20px rgba(128, 128, 128, 0.911);
}

.serviceTitle{
    font-size: 2.5rem;
    font-weight: 800;
    /* color: #6130DC; */
    /* color: #ff4747; */
}

.serviceDescription{
    color: rgb(35, 35, 35);
    font-size: 1.1rem;
    text-wrap: wrap;
}

.textWrap{
    text-wrap: wrap;
}

@media (max-width: 768px){
    .serviceComContainerRow{
        padding: 1rem 1rem;
        height: fit-content;
        flex-direction: column-reverse;
    }
    .serviceComContainerRowRev{
        padding: 1rem 1rem;
        height: fit-content;
        flex-direction: column-reverse;
    }
    .serviceLeftContainer{
        width: auto;
        padding-top: 1.5rem;
        margin: 1rem;
    }
    .serviceRightContainer{
        width: auto;
        padding-top: 1.5rem;
    }
    .serviceTitle{
        font-size: 32px;
        font-weight: 700;
    }
    .serviceDescription{
        font-size: 1.2rem;
        text-align: justify;
    }
    .serViceImg{
        /* width: 100%; */
        height: 240px;
    }
}


