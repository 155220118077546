.our_services{
    position: relative;
    /* background-color: rgb(247, 247, 255); */
    background-image: linear-gradient(rgb(247, 247, 255), rgb(253, 246, 246));
    padding: 6rem 3rem;
    text-align: center;
}

.our_services .col-lg-4 h2{
    font-size: 1.3rem;
}

.our_services .col-lg-4 p{
    color: rgba(126, 126, 126, 0.911);
    margin: 1rem 2rem 5rem 2rem;
}

.serviceCard{
    width: 30%;
    /* background-color: rgba(231, 241, 250, 0.557); */
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem;
    cursor: default;
}

.serviceCard h4{
    font-weight:750; 
    margin-top: 0.3rem;
    color: #091238e6;
}

.serviceCard p{
    color: rgba(44, 44, 44, 0.89);
}

.serviceCard:hover{
    box-shadow: 1px 1px 5px rgb(216, 216, 216);
}

.service_title{
    color: #091238e6;
    font-size: 2.3rem;
    font-weight: 800;
    margin-bottom: 3rem;
}

.service_title_subheading{
    font-weight: 300;
    font-size: 1.3rem;
}

.icon_size{
    height: 4rem;
    width: 4rem;
}
@media (max-width:768px){
    .our_services{
        padding: 6rem 0rem;
    }
    .service_title{
        font-size: 2rem;
        /* margin: 0% 8% 3% 8%; */
    }
    .serviceCard{
        width: 100%;
        padding: 0rem;
    }
    .serviceCard h4{
        font-weight:600; 
    }
    .our_services .col-lg-4 p{
        font-size: 0.8rem;
     
    }
    .our_services .col-lg-4 h2{
        font-size: 1.1rem;
    }
    .our_services .col-lg-4 p{
        margin: 1rem 1rem 5rem 1rem;
    }
}
.partner-img img{
    width: 200px;
    height: 200px;
    margin: 1rem;
}
