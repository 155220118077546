.aboutCom {
    height: 94vh;
    width: 100%;
    display: flex;
}

.textComCard {
    height: 100%;
    width: 50%;
    padding: 8rem 4rem 10rem 8rem;
    background-image: linear-gradient(#fdf6f6, #fdf9f6);
}

.imageComCard {
    height: 100%;
    width: 50%;
    padding: 5rem 6rem 5rem 0rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* background-color: white; */
    background-image: linear-gradient(#fdf6f6, #fdf9f6);
}


.imgComCard {
    border-radius: 0.5rem;
    margin: 0.25rem;
}

.text-com-card {
    height: 100%;
    width: 100%;
}

.text-com-card div p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.aboutComSliderContainer {
    margin: 0.25rem;
    box-shadow: 2px 2px 10px rgb(211, 211, 211);
    border-radius: 0.5rem;
}

.size1 {
    width: 56%;
    height: 60%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(41, 41, 41, 0.5);
    background: url('../../../public/images/msi/event-e5.webp');
    background-size: 100% 100%;
    animation: firstGallary 60s 10s infinite;
}

.size2 {
    width: 38%;
    height: 60%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(41, 41, 41, 0.5);
    background: url('../../../public/images/store-launch/store-e6.webp');
    background-size: 100% 100%;
    animation: secondGallary 70s 10s infinite;
}

.size3 {
    width: 38%;
    height: 40%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(41, 41, 41, 0.5);
    background: url('../../../public/images/esport/esport1.webp');
    background-size: 100% 100%;
    animation: thirdGallary 30s 5s infinite;
}

.size4 {
    width: 56%;
    height: 40%;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(41, 41, 41, 0.5);
    background: url('../../../public/images/logitech/logitech-e13.webp');
    background-size: 100% 100%;
    animation: fourthGallary 70s 10s infinite;
}

.pictureGridAboutCom {
    width: 100%;
    height: 100%;
}

.aboutComSliderImage {
    width: 100%;
    height: 250px;
    border-radius: 0.5rem;
}

@keyframes firstGallary {
    0% {
        background: url('../../../public/images/msi/event-e1.webp');
        background-size: 100% 100%;
    }

    25% {
        background: url('../../../public/images/msi/event-e2.webp');
        background-size: 100% 100%;
    }

    50% {
        background: url('../../../public/images/msi/event-e3.webp');
        background-size: 100% 100%;
    }

    75% {
        background: url('../../../public/images/msi/event-e4.webp');
        background-size: 100% 100%;
    }

    100% {
        background: url('../../../public/images/msi/event-e5.webp');
        background-size: 100% 100%;
    }
}

@keyframes secondGallary {
    0% {
        background: url('../../../public/images/store-launch/store-e1.webp');
        background-size: 100% 100%;
    }

    25% {
        background: url('../../../public/images/store-launch/store-e2.webp');
        background-size: 100% 100%;
    }

    50% {
        background: url('../../../public/images/store-launch/store-e3.webp');
        background-size: 100% 100%;
    }

    70% {
        background: url('../../../public/images/store-launch/store-e4.webp');
        background-size: 100% 100%;
    }

    85% {
        background: url('../../../public/images/store-launch/store-e5.webp');
        background-size: 100% 100%;
    }

    100% {
        background: url('../../../public/images/store-launch/store-e6.webp');
        background-size: 100% 100%;
    }
}

@keyframes thirdGallary {
    0% {
        background: url('../../../public/images/esport/esport1.webp');
        background-size: 100% 100%;
    }

    33% {
        background: url('../../../public/images/esport/esport2.webp');
        background-size: 100% 100%;
    }

    67% {
        background: url('../../../public/images/esport/esport3.webp');
        background-size: 100% 100%;
    }
}

@keyframes fourthGallary {
    0% {
        background: url('../../../public/images/logitech/logitech-e5.webp');
        background-size: 100% 100%;
    }

    25% {
        background: url('../../../public/images/logitech/logitech-e7.webp');
        background-size: 100% 100%;
    }

    50% {
        background: url('../../../public/images/logitech/logitech-e8.webp');
        background-size: 100% 100%;
    }

    75% {
        background: url('../../../public/images/logitech/logitech-e10.webp');
        background-size: 100% 100%;
    }

    100% {
        background: url('../../../public/images/logitech/logitech-e13.webp');
        background-size: 100% 100%;
    }
}



@media (max-width: 768px){
    .aboutCom{
        height: fit-content;
        flex-direction: column;
    }
    .textComCard {
        width: 100%;
        padding: 5rem 1rem 0rem 1rem;
    }
    .imageComCard {
        width: 100%;
        height: 300px;
        padding: 0.25rem;
    }
    .text-com-card h1{
        font-size: 36px;
    }
}