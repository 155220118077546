/**********************************/
/*********** Brand CSS ************/
/**********************************/
.brand {
    padding: 1.5rem 0;
    /* margin-bottom: 12rem; */
    /* background-color: #fdf6f6; */
    background-image: linear-gradient(#fdf6f6, #fdf6f6);
    width: 100%;
    position: fixed;
    z-index: 100;
}

.nav-stickyy {
    /* background-color: rgba(253, 246, 246, 0.85); */
    /* background-color: #ffffffe6; */
    background-image: linear-gradient(#ffffff, #ffffff, #ffffff68);
}

.brand_LeftRight{
    margin-left: 4rem;
    margin-right: 4rem;
    display: flex;
}

.brand .b-logo {
    overflow: hidden;
    margin-left: 10rem;
    width: 97px;
    height: 62px;
    /* background-color: red; */
    /* text-align: center; */
}

.brand .b-logo a img {
    margin-top: 5px;
    width: 80%;
    height: 75%;
}



@media (min-width: 768px) {
    .brand {
        max-height: 6rem;
    }
}

@media (max-width: 768px) {
    .brand .b-logo,
    .brand .b-ads,
    .brand .b-search {
        text-align: center;
        margin-left: 2rem;
    }
    .brand .b-logo a img {
        max-width: 100%;
        max-height: 2.5rem;
    }
    .title_brand{
        font-size: 1rem;
    }
    .brand_LeftRight{
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

