/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.nav-bar {
    position: relative;
    width: 100%;
    /* animation-name: navbar_animate;
    animation-duration: 2.5s;
    animation-delay: 1s; */
}
.nav_LeftRight_Margin{
    margin-left: 4rem;
    margin-right: 4rem;
}

.navbarfontColor{
    font-weight: 600;
    color: black;
}

.nav-bar .navbar {
    padding: 0.2rem;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    padding: 10px 10px 8px 10px;
    color: #000000;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    /* border-bottom: 2px solid #000000; */
    transition: none;
}

.nav-bar .dropdown-menu {
    /* width: 300px; */
    margin-top: 0;
    padding: 1rem;
    border: 0;
    border-radius: 0.15rem;
    box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.781);
    background: #ffffff;
}

.dropdown-item:hover{
    background-color: antiquewhite;
}

@media (min-width: 768px) {
    /* .nav-bar,
    .nav-bar .navbar {
        background: rgb(0, 0, 0) !important;
    }
     */
    .nav-bar .navbar-brand {
        display: none;
    }
    .nav-bar a.nav-link {
        padding: 0.45rem 0.9rem;
        font-size: 1rem;
        text-transform: uppercase;
    }
}

@media (max-width: 768px) {   

    .nav_LeftRight_Margin{
        margin-left: 2rem;
        margin-right: 0.6rem;
    }
    .nav-bar,
    .nav-bar .navbar {
        padding: 1px 0 !important;
        margin: 0;
        /* background: rgb(0, 0, 0) !important; */
    }
    .nav-bar .navbar-brand {
        font-size: 1.5rem;
    }
    .menu_btn{
        margin-right: 2rem;
        max-width: 4rem;
        max-height: 3rem;
    }
    .navbar-toggler-icon{
        width: 2rem !important;
        height: 2rem !important;
    }

    .nav-bar .social {
        margin-bottom: 1rem;
    }

    .nav-bar a.nav-link {
        padding: 0.3rem;
    }
    .nav-bar .dropdown-menu {
        box-shadow: none;
    }
    .nav-bar .social {
        margin-top: 15px;
    }
    .nav-bar .social a {
        width: 30px !important;
        height: 30px !important;
        font-size: 14px !important;
    }
    /*
    .nav-bar .social {
        display: none;
    } */
}

.nav-bar .social {
    font-size: 0;
    overflow: hidden;
}

.nav-bar .social a {
    display: inline-block;
    margin-left: 8px;
    width: 40px;
    height: 40px;
    padding: 3px 0;
    text-align: center;
    font-size: 20px;
    /* background: black; */
    /* border: 2px solid #000000; */
    border-radius: 5px;
}

.nav-bar .social a:hover {
    background: #000000;
    /* border: 2px solid #ffffff; */
}

.nav-bar .social a i {
    color: #000000;
}

.nav-bar .social a:hover i {
    color: rgb(255, 255, 255);
}

/* @keyframes navbar_animate {
    0%{left: 100%;}
    50%{left: 0%;}
    60%{left: 5%}
    70%{left: 0%;}
    78%{left: 3%;}
    85%{left: 0%;}
    93%{left: 1%;}
    100%{left: 0%;}
} */