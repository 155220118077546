.comStatsCss{
    width: 98.9vw;
    height: 100vh;
    padding: 6rem 12rem 12rem 12rem;
    text-align: center;
    /* background-color: red; */
    background-image: linear-gradient(#fdf9f6, #f7f7ff);
    /* background-color: rgb(253, 249, 246); */
}

/* 581D8F */
/* 6130DC */

.comStatsCardContainer{
    height: 85%;
    width: 100%;
    padding: 2rem;
    display: flex;
}

.comStatsCard{
    height: 100%;
    width: 33.33%;
    background-color: white;
    border-radius: 1rem;
    margin: 0rem 0.6rem;
    text-align: center;
    padding: 2.5rem;
}

.comImg{
    width: 100px;
    height: 100px;
    padding: 1rem;
    border-radius: 50%;
    background-color: #ff555566;
}

.comStatsCard h1{
    font-size: 3.4rem;
    font-weight: 900;
    color: rgb(255, 71, 71);
}

.comHeading{
    color: #091238e6;
    font-size: 2.5rem;
    font-weight: 800;
}

.comSummary{
    font-size: 1.2rem;
}


@media (max-width: 768px){
    .comStatsCss{
        height: fit-content;
        padding: 6rem 1rem 6rem 1rem;
    }
    .comHeading{
        font-size: 32px;
        font-weight: 700;
    }
    .comSummary{
        text-align: justify;
    }
    .comStatsCardContainer{
        flex-direction: column;
        gap: 1rem;
    }
    .comStatsCard{
        width: 100%;
    }
}