/**********************************/
/********** Footer CSS ************/
/**********************************/
.footer {
    position: relative;
    padding: 60px 0 0 0;
    background-image: linear-gradient(#111111,#000000);
    /* background: #111111; */
}

.footer .footer-widget {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
}

.footer .footer-widget .title {
    color: #ffffff;
    font-size: 25px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 3px double #ffffff;
}

.footer .footer-widget p {
    color: #ffffff;
}

.footer .footer-widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer .footer-widget ul li {
    margin-bottom: 12px; 
}

.footer .footer-widget ul li:last-child {
    margin-bottom: 0; 
}

.footer .footer-widget ul li a {
    color: #ffffff;
    white-space: nowrap;
    display: block;
    line-height: 23px;
}

.footer .footer-widget ul li a::before {
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    padding-right: 5px;
}

.footer .footer-widget ul li a:hover {
    padding-left: 10px;
    color: #FFAF61;
}

.footer .contact-info p {
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 16px;
}

.footer .contact-info p i {
    color: #ffffff;
    margin-right: 5px;
}

.footer .social {
    position: relative;
    width: 100%;
}

.footer .social a {
    display: inline-block;
    margin: 10px 5px 0 0;
    width: 35px;
    height: 35px;
    padding: 5px 0;
    text-align: center;
    font-size: 18px;
    /* background: #ffffff; */
    border-radius: 4px;
}

.footer .social a i {
    color: white;
}

.footer .social a:hover {
    background: #FFAF61;
}

.footer .social a:hover i {
    color: #ffffff;
}

.footer .newsletter {
    position: relative;
    width: 100%;
}

.footer .newsletter form {
    position: relative;
    width: 100%;
}

.footer .newsletter input {
    width: 90%;
    height: 35px;
    padding: 0;
    margin: 0;
    border: 1px solid #ffffff;
    border-radius: 4px;
}

.footer .newsletter .btn {
    position: absolute;
    width: 80px;
    height: 35px;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border: none;
    background: #111111;
    color: #ffffff;
    font-size: 14px;
    border: 1px solid #ffffff;
    border-radius: 0 4px 4px 0;
}

.footer .newsletter .btn:hover {
    background: gray;
}

.footer .newsletter input:focus,
.footer .newsletter .btn:focus {
    box-shadow: none;
}


/**********************************/
/******** Footer Menu CSS *********/
/**********************************/
.footer-menu {
    position: relative;
    background: #111111;
}

.footer-menu .container {
    padding: 15px 0;
    border-top: 1px solid #000000;
}

.footer-menu .f-menu {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* text-align: center; */
}

.footer-menu .f-menu a {
    color: #ffffff;
    font-size: 14px;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #ffffff;
}

.footer-menu .f-menu a:hover {
    color: #FFAF61;
}

.footer-menu .f-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}


/**********************************/
/******** Footer Bottom CSS *******/
/**********************************/
.footer-bottom {
    position: relative;
    padding: 25px 0;
    background: #000000;
}

.footer-bottom .copyright {
    text-align: left;
}

.footer-bottom .template-by {
    text-align: right;
}

.footer-bottom .copyright p,
.footer-bottom .template-by p {
    color: #ffffff;
    font-weight: 400;
    margin: 0;
}

.footer-bottom .copyright p a,
.footer-bottom .template-by p a {
    font-weight: 600;
    color: #ffffff
}

.footer-bottom .copyright p a:hover,
.footer-bottom .template-by p a:hover {
    color: #ffffff;
}

@media (max-width: 768.98px) {
    .footer-bottom .copyright,
    .footer-bottom .template-by {
        text-align: center; 
    } 
}

@media (max-width: 768px){
    .LeftRight_Margin{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .footer .footer-widget .title {
        font-size: 17px;
    }
    .footerFontSize{
        font-size: 15px;
    }
    .footer .social a {
        width: 30px;
        height: 30px;
        padding: 5px 0;
        font-size: 15px;        
    }
    .footer .footer-widget {
        margin-bottom: 35px;
    }
    .footer .footer-widget ul li {
        margin-bottom: 4px; 
    }
    .footer{
        padding: 35px 0 0 0;
    }
}