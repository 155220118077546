.partner-container{
    width: 100%;
    height: 90vh;
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#fdf6f6, #ffffff);
}

.brand-logos{
    width: 80%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: blue; */
    gap:1rem;
}

.partner-container h1{
    font-weight: 800;
    color: #2b1212e6;
}

.partner-brand-subtitle{
    width: 35%;
    color: rgb(72, 72, 72);
    font-size: large;
    text-align: center;
}

.brand-logo{
    width: 23%;
    height: 26%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 1em; */
    background-color: #f9f0f0b8;
}

.brand-logo:hover{
    background-color: #f9f0f0;
    box-shadow: 5px 5px 10px rgba(217, 217, 217, 0.564);
}

.brand-logo-icon:hover{
    transform: scale(1.02);
}

.brand-logo-icon{
    width: 80%;
    height: 80%;
}


@media (max-width: 768px){
    .brand-logos{
        width: 115%;
        height: 34%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* background-color: blue; */
        gap:1rem;
    }  
    .partner-brand-subtitle{
        width: 80%;
        color: rgb(72, 72, 72);
        font-size: medium;
        text-align: center;
    }
    .partner-container{
        gap: 1rem;
    }  
    .partner-container h1{
        font-size: 30px;
        font-weight: 700;
    }
    .brand-logo{
        width: 33%;
        height: 23%;
    }
}